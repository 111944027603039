import { AxiosError, AxiosResponse } from 'axios';
import {
  removeUserSession
} from '../utils/user-session';
import {
  LOGIN_ENDPOINT,
  REGISTER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
  SEND_PASSWORD_RESET_EMAIL_ENDPOINT
} from '../constants';
import { HttpRequestResponse } from '../models/web/http-request-response';
import { LoginResponse } from '../models/web/login-response';
import { RegistrationResponse } from '../models/web/registration-response';
import { axiosPublic } from '../models/axios/axios-public';
import { getEndpointResponse } from '../utils/endpoint-response';

export const register = async (
  email: string,
  password: string
): Promise<HttpRequestResponse<RegistrationResponse>> => {
  const response: AxiosResponse | AxiosError = await axiosPublic
    .post<RegistrationResponse>(REGISTER_ENDPOINT, {
      username: email,
      password,
      roleId: '696d0d76-32cb-4756-a4ea-0d102d333d19'
    })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
}

export const signin = async (
  user: string,
  password: string
): Promise<HttpRequestResponse<LoginResponse>> => {
  const response: AxiosResponse | AxiosError = await axiosPublic
    .post<LoginResponse>(LOGIN_ENDPOINT, {
      username: user,
      password
    })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
};

export const sendPasswordResetEmail = async (
  email: string
): Promise<HttpRequestResponse<void>> => {
  const response: AxiosResponse | AxiosError = await axiosPublic
    .post<void>(SEND_PASSWORD_RESET_EMAIL_ENDPOINT, {
      email
    })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
};

export const reset = async (
  token: string,
  password: string
): Promise<HttpRequestResponse<void>> => {
  const response: AxiosResponse | AxiosError = await axiosPublic
    .patch<LoginResponse>(RESET_PASSWORD_ENDPOINT, {
      token,
      password
    })
    .catch((err: AxiosError): AxiosError => err);

  return getEndpointResponse(response);
};

export const signout = (): void => removeUserSession();
