import axios from 'axios';
import { getToken } from '../../utils/user-session';

const axiosPrivate = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token !== null) {
      config.headers = {
        ...config.headers, "x-access-token": token
      }
    }

    return config;
  },
  async (error) => await Promise.reject(error)
)

export default axiosPrivate;
