import React, { useState } from 'react';
import { useLocation, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Heading,
  Input, InputGroup, InputRightElement,
  Link,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react';
import { useAuth } from '../components/auth';
import { Auth } from '../models/auth';
import { LoginResponse } from '../models/web/login-response';
import {
  ALERT_DEFAULT_ERROR_TITLE,
  DASHBOARD_PAGE_ROUTE,
  LINK_MEMBER_PAGE_ROUTE,
  LINK_COLOR,
  REGISTRATION_PAGE_ROUTE, SEND_VERIFICATION_EMAIL_ENDPOINT, FORGOT_PASSWORD_PAGE
} from '../constants';
import { HttpStatusCode } from '../models/http-status-code';
import {MessageOnlyResponse} from "../models/web/message-only-response";
import AlertMessage from "../components/alert-message";
import { openLinkInNewTab } from '../helpers';

function Login(): JSX.Element {
  const [alertDescription, setAlertDescription] = useState<string>();
  const [sendVerificationEmailLink, setSendVerificationElementLink] = useState<any>();
  const [showPassword, setShowPassword] = useState(false);
  const auth: Auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  let from: string = location.state?.from?.pathname ?? '/';
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(
          'Mmm... Esto no parece una dirección de correo electrónico válida.'
        )
        .required('Ingresá una dirección de correo electrónico.'),
      password: Yup.string().required('Ingresá una contraseña.')
    }),
    onSubmit: async (values) => {
      from = from === '/' ? DASHBOARD_PAGE_ROUTE : from;
      const response = await auth.login(values.email, values.password);
      let responseContent: LoginResponse | MessageOnlyResponse;
      switch (response.statusCode) {
        case HttpStatusCode.Ok:
          responseContent = (response.response as LoginResponse);
          from =
            !responseContent.user.selectedMember || !responseContent.user.members
              ? LINK_MEMBER_PAGE_ROUTE
              : from;
          navigate(from, { replace: true });
          break;
        case HttpStatusCode.Unauthorized:
          responseContent = (response as MessageOnlyResponse);
          switch (responseContent.message) {
            case 'Credentials are invalid':
              setAlertDescription('Las credenciales ingresadas son inválidas. Verificalas y probá de nuevo.');
              break;
            case 'User is not active':
              setAlertDescription('La cuenta aún no ha sido verificada. Por favor, revisá tu bandeja de entrada: te mandamos un correo electrónico para verificarla. Si no podés encontrar dicho correo electrónico, podés solicitar uno nuevo haciendo click en el siguiente botón:');
              setSendVerificationElementLink(<Button role="link" onClick={() => openLinkInNewTab(SEND_VERIFICATION_EMAIL_ENDPOINT.replace('{Email}', formik.values.email))} display="block">Solicitar correo electrónico</Button>);
              break;
          }
          break;
        default:
          setAlertDescription('¡Ups! Algo salió mal. Intentalo de nuevo más tarde...');
          break;
      }
    }
  });

  const handleShowPasswordClick = (): void => setShowPassword(!showPassword);

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing={3}>
        <VStack width={['full', 'full', '40%']} align="flex-start" spacing={3}>
          <Heading>Iniciar sesión</Heading>
          <Text>Por favor, ingresá tus credenciales.</Text>
        </VStack>
        <VStack width="full">
          <SimpleGrid width={['full', 'full', '40%']} spacing={3}>
            <GridItem>
              <FormControl
                isInvalid={
                  (formik.touched.email ?? false) && formik.errors.email != null
                }
              >
                <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorMessage>{formik.errors.email}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem>
              <FormControl
                isInvalid={
                  (formik.touched.password ?? false) &&
                  formik.errors.password != null
                }
              >
                <FormLabel htmlFor="password">Contraseña</FormLabel>
                <InputGroup>
                  <Input
                    id="password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputRightElement width={['30%', '30%', '15%']}>
                    <Button height="1.75rem" size="sm" backgroundColor="blackAlpha.200" onClick={handleShowPasswordClick}>
                      {showPassword ? 'Ocultar' : 'Mostrar'}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>{formik.errors.password}</FormErrorMessage>
              </FormControl>
            </GridItem>
            <GridItem marginTop={5}>
              <Button type="submit" width="full" colorScheme="green">
                Ingresar
              </Button>
            </GridItem>
            <AlertMessage status="error" title={ALERT_DEFAULT_ERROR_TITLE} description={alertDescription} hidden={!alertDescription} additionalDescriptionElement={sendVerificationEmailLink} />
          </SimpleGrid>
        </VStack>
        <VStack width={['full', 'full', '40%']} align="flex-start">
          <Text width="full" align="right"><Link as={RouterLink} to={FORGOT_PASSWORD_PAGE} color={LINK_COLOR}>Olvidé mi contraseña</Link></Text>.
          <Text fontSize="xl">¿No tenés una cuenta?</Text>
          <Text align="left">Podés crear una, de manera muy simple y rápida, haciendo click{' '}
            <Link as={RouterLink} to={REGISTRATION_PAGE_ROUTE} color={LINK_COLOR}>acá</Link>.
          </Text>
        </VStack>
      </VStack>
    </form>
  );
}

export default Login;
