import React, { useState } from 'react';
import { Member } from '../models/member';
import { Center } from '@chakra-ui/react';
import { useAuth } from '../components/auth';
import Credential from "../components/credential";

function MyMembershipCredential(): JSX.Element {
  const auth = useAuth();
  const [member] = useState(auth?.user?.selectedMember as Member);
  if (member == null) {
    return <></>;
  }

  return (
    <Center width="100%" marginTop="5%">
      <Credential memberId={member.id} memberNumber={member.memberNumber.toString()} firstName={member.firstName} lastName={member.lastName}
                  documentNumber={member.documentNumber.toString()} membershipDate={new Date(member.membershipDate).toString()} memberType={member.memberType.value} imageLink={member.imagePath}
      />
    </Center>
  );
}

export default MyMembershipCredential;
