// This UI
export const BACKOFFICE_BASE_URL = process.env.REACT_APP_BACKOFFICE_BASE_URL;
export const GET_MEMBER_DATA_UI_URL = `${BACKOFFICE_BASE_URL}/members/status/{MemberId}`;

// External services
export const BACKEND_API_URL = (process.env.REACT_APP_BACKEND_API_BASE_URL as string);
export const BACKEND_API_V2_URL = (process.env.REACT_APP_BACKEND_API_V2_BASE_URL as string);
export const QR_CODE_BASE_URL = 'https://api.qrserver.com/v1/create-qr-code/';
export const QR_CODE_SIZE = '?size=150x150';
export const QR_CODE_ENDPOINT = `${QR_CODE_BASE_URL}${QR_CODE_SIZE}&data={MemberUrl}`;

// HTTP status codes
export const HTTP_STATUS_CODE_OK = 200;
export const HTTP_STATUS_CODE_NO_CONTENT = 204;

// Users endpoints
export const REGISTER_ENDPOINT = `${BACKEND_API_URL}/users`;
export const LOGIN_ENDPOINT = `${BACKEND_API_URL}/users/login`;
export const PATCH_USER_DATA_ENDPOINT = `${BACKEND_API_URL}/users/{UserId}`;
export const SEND_VERIFICATION_EMAIL_ENDPOINT = `${BACKEND_API_URL}/users/send-verification-email/{Email}`;
export const SEND_PASSWORD_RESET_EMAIL_ENDPOINT = `${BACKEND_API_URL}/users/send-reset-email`;
export const RESET_PASSWORD_ENDPOINT = `${BACKEND_API_URL}/users/reset-password`;

// Members endpoints
export const GET_MEMBER_DATA_ENDPOINT = `${BACKEND_API_URL}/members/{MemberId}`;
export const PATCH_PARTNER_DATA_ENDPOINT = `${BACKEND_API_URL}/members/{MemberId}`;
export const PATCH_PARTNER_DATA_ENDPOINT_V2 = `${BACKEND_API_URL}/members/v2/{MemberId}`;
export const FIND_MEMBER_DATA_ENDPOINT = `${BACKEND_API_URL}/members/find`;

// Product endpoints
export const GET_MEMBERSHIP_FEES_ENDPOINT = `${BACKEND_API_URL}/products/membership-fees?memberId={MemberId}`;
export const GET_MEMBERSHIP_FEES_ENDPOINT_V2 = `${BACKEND_API_V2_URL}/members/{MemberId}/membership-fees`;
export const GET_ACTIVITIES_FEES_ENDPOINT = `${BACKEND_API_URL}/products/activities-fees?memberId={MemberId}`;
export const GET_ACTIVITIES_FEES_FOR_MEMBERSHIP_FEE_BY_MEMBER_ID_ENDPOINT = `${BACKEND_API_URL}/products/membership-fees/activities-fees?memberId={MemberId}&membershipFeeId={MembershipFeeId}`;

// Payment endpoints
export const POST_PAYMENT_ENDPOINT = `${BACKEND_API_URL}/payments`;
export const POST_PAYMENT_ENDPOINT_V2 = `${BACKEND_API_V2_URL}/payments`;
export const PUT_PAYMENT_UPLOAD_RECEIPT_ENDPOINT = `${BACKEND_API_URL}/payments/upload-receipt/{PaymentId}`;
export const MERCADO_PAGO_ENDPOINT = `${BACKEND_API_URL}/payments/mercadopago`;

// Pages routes
export const REGISTRATION_PAGE_ROUTE = '/registration';
export const LOGIN_PAGE_ROUTE = '/login';
export const LINK_MEMBER_PAGE_ROUTE = '/link-member';
export const DASHBOARD_PAGE_ROUTE = '/dashboard';
export const MY_DATA_PAGE_ROUTE = '/my-data';
export const MY_MEMBERSHIP_FEES_PAGE_ROUTE = '/my-membership-fees';
export const MY_MEMBERSHIP_CREDENTIAL_PAGE_ROUTE = '/my-membership-credential';
export const MY_ACTIVITIES_PAGE = '/my-activities';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const PAYMENT_PAGE = '/payment-page';

// Alerts
export const ALERT_DEFAULT_ERROR_TITLE = '¡Tenemos un problema!';

// Toasts
export const TOAST_SUCCESS = 'success';
export const TOAST_ERROR = 'error';
export const TOAST_SUCCESS_TITLE = '¡Listo! Todo OK 👌';
export const TOAST_ERROR_TITLE = '¡Uh! Se produjo un error 💥';
export const SHORT_ERROR_DESCRIPTION = 'Por favor contactate con secretaría para comentarles sobre este inconveniente.';
export const TOAST_ERROR_DESCRIPTION = 'Algo salió mal. Por favor contactate con secretaría para comentarles sobre este inconveniente.';

// Member data update
export const TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_ERROR =
  'No hemos podido modificar tus datos personales 😰 Por favor, intentalo nuevamente más tarde...';
export const TOAST_PARTNER_DATA_UPDATE_DESCRIPTION_SUCCESS =
  'Tus datos personales han sido modificados exitosamente.';

// Password reset
export const TOAST_PASSWORD_RESET_ERROR = 'No se ha podido actualizar tu contraseña. Posiblemente el enlace haya expirado. Intentá realizar el proceso nuevamente.';
export const TOAST_PASSWORD_RESET_SUCCESS = 'Ahora podés iniciar sesión utilizando tu nueva contraseña.';

// Styles
export const LINK_COLOR = 'blue.500';

// Payment methods
export const PAYMENT_METHOD_ID_MERCADO_PAGO = 'ebc10387-d7d8-4518-97aa-2c14eed5d6d8';
export const PAYMENT_METHOD_ID_BANK_TRANSFER = '85165b1e-868c-4752-b055-3961b93a8e35';
export const MERCADO_PAGO_PUBLIC_KEY = (process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY as string) ?? 'TEST-38e63bab-176b-4704-b25c-bd54e47b4cfa';

// Manual check statuses
export const PAYMENT_VALIDATION_STATUS_PENDING = '78031447-26f0-40b8-9f6b-92977ae6da47';
export const PAYMENT_VALIDATION_STATUS_VALIDATION = '863bca05-4aac-4330-9ea7-12aae698954b';
export const PAYMENT_VALIDATION_STATUS_ERROR = '9fd09980-eb42-492f-a51f-4e7c98b43702';
