import { AxiosError, AxiosResponse } from 'axios/index';
import { HttpRequestResponse } from '../models/web/http-request-response';

export const isInstanceOfAxiosResponse = (response: any): response is AxiosResponse => 'data' in response;

export const getEndpointResponse = <T>(axiosResponse: AxiosResponse | AxiosError): HttpRequestResponse<T> =>
  isInstanceOfAxiosResponse(axiosResponse)
    ? {
      message: axiosResponse.data.message,
      statusCode: axiosResponse.status,
      response: axiosResponse.data
    }
    : {
      message: (axiosResponse.response?.data as { message: string })?.message ?? axiosResponse.message,
      statusCode: axiosResponse.response?.status ?? 500,
      response: null
    };
